"use client"

import { useEffect } from "react"
import MessagePage from "ui/MessagePage"

export default function Error({
  error,
}: {
  error: Error & { digest?: string }
  reset: () => void
}) {
  useEffect(() => {
    // eslint-disable-next-line no-console
    console.error(error)
  }, [error])

  return (
    <MessagePage
      text={"מצטערים, האתר נמצא בשיפוצים"}
      imageDesktop={"/images/underConstructions.png"}
      imageMobile={"/images/underConstructionsMobile.png"}
      imageHeight={429}
      imageWidth={616}
    />
  )
}
